type BaseModel = "gpt-4" | "gpt-3.5-turbo";

type Model = {
    id: string;
    name: string;
    baseModel: BaseModel;
    context: number;
    price: {
        inputCostPerUnit: number;
        outputCostPerUnit: number;
        unitTokens: number;
    };
    provider: Provider
};

type Provider = {
    name: string;
    source: string;
}

const OpenAI: Provider = {
    name: "OpenAI",
    source: "https://openai.com/pricing"
}

const openAIModels: Model[] = [
    {
        id: "openai-gpt-4-8k-context",
        name: "GPT-4 8k",
        baseModel: "gpt-4",
        context: 8000,
        price: {
            inputCostPerUnit: 0.03,
            outputCostPerUnit: 0.06,
            unitTokens: 1000
        },
        provider: OpenAI
    },
    {
        id: "openai-gpt-4-32k-context",
        name: "GPT-4 32k",
        baseModel: "gpt-4",
        context: 32000,
        price: {
            inputCostPerUnit: 0.06,
            outputCostPerUnit: 0.12,
            unitTokens: 1000
        },
        provider: OpenAI
    },
    {
        id: "openai-gpt-3.5-turbo-4k-context",
        name: "GPT-3.5 4k (ChatGPT)",
        baseModel: "gpt-3.5-turbo",
        context: 4000,
        price: {
            inputCostPerUnit: 0.0015,
            outputCostPerUnit: 0.002,
            unitTokens: 1000
        },
        provider: OpenAI
    },
    {
        id: "openai-gpt-3.5-turbo-16k-context",
        name: "GPT-3.5 16k",
        baseModel: "gpt-3.5-turbo",
        context: 16000,
        price: {
            inputCostPerUnit: 0.003,
            outputCostPerUnit: 0.004,
            unitTokens: 1000
        },
        provider: OpenAI
    },
];

export const models = openAIModels;