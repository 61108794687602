import { Select, Slider, SliderTrack, SliderFilledTrack, SliderThumb, Box, Card, Tabs, TabList, Tab, TabPanels, TabPanel, Textarea, Button, NumberInput, NumberInputField, Container, Table, TableCaption, TableContainer, Tbody, Td, Th, Thead, Tr, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import "./style.css";
import { models } from "./const/pricing";
import GPT3Tokenizer from 'gpt3-tokenizer';

const tokenizer = new GPT3Tokenizer({ type: 'gpt3' });

const countWords = (str: string) => {
    const matches = str.match(/\b\w+\b/g);
    return matches ? matches.length : 0;
}

const countTokensFromWordCount = (cnt: number) => {
    return cnt ? Math.ceil(cnt * 4 / 3) : 0;
}

const countTokensFromString = (str: string) => {
    const encoded: { bpe: number[]; text: string[] } = tokenizer.encode(str);
    return encoded.bpe.length;
}

function roundToDecimalPlaces(number: number, decimalPlaces: number) {
    const factor = Math.pow(10, decimalPlaces);
    return Math.round(number * factor) / factor;
}

const calculateCost = (modelId: string, inputTokens: number, outputTokens: number) => {
    const selectedModelData = models.find((model) => model.id === modelId);
    if (!selectedModelData) {
        return 0;
    }

    if (inputTokens === 0 || outputTokens === 0) {
        return 0;
    }

    const inputCost = (inputTokens / selectedModelData.price.unitTokens) * selectedModelData?.price.inputCostPerUnit;
    const outputCost = (outputTokens / selectedModelData.price.unitTokens) * selectedModelData?.price.outputCostPerUnit;

    return inputCost + outputCost
}

export const Calculator = () => {
    const options = models;
    // State for the selector
    const [selectedModel, setSelectedModel] = useState("openai-gpt-3.5-turbo-4k-context");
    const [tabIndex, setTabIndex] = useState(0);

    const [inputMsg, setInputMsg] = useState("");
    const [outputMsg, setOutputMsg] = useState("");

    const [inputWordCount, setInputWordCount] = useState(0);
    const [outputWordCount, setOutputWordCount] = useState(0);

    const [inputTokenCount, setInputTokenCount] = useState(0);
    const [outputTokenCount, setOutputTokenCount] = useState(0);

    const [cost, setCost] = useState(0);

    const [inputTokens, setInputTokens] = useState(0);
    const [outputTokens, setOutputTokens] = useState(0);


    const getTokens = (tabIndex: number) => {
        switch (tabIndex) {
            case 0:
                setInputTokens(countTokensFromString(inputMsg));
                setOutputTokens(countTokensFromString(outputMsg));
                break;
            case 1:
                setInputTokens(countTokensFromWordCount(inputWordCount));
                setOutputTokens(countTokensFromWordCount(outputWordCount));
                break
            case 2:
                setInputTokens(inputTokenCount);
                setOutputTokens(outputTokenCount);
                break;
        }
        const cost = calculateCost(selectedModel, inputTokens, outputTokens);
        setCost(cost);
    }

    useEffect(() => {
        const cost = calculateCost(selectedModel, inputTokens, outputTokens);
        setCost(cost);
    }, [inputTokens, outputTokens]);

    return (
        <Container
            maxW={{ base: "100%", md: '600px' }}
        // bg={`url('https://media.indiedb.com/images/games/1/7/6034/fpstmenuconceptwallpaper.jpg')`}
        // backgroundSize="cover"
        // backgroundPosition="center"
        // backgroundRepeat="no-repeat"
        >
            <div style={{ marginTop: '20px', marginBottom: '50px' }}>
                <p style={{ marginBottom: '10px' }}>Choose your model</p>
                <Select onChange={(event) => setSelectedModel(event.target.value)}>
                    {options.map((option) => {
                        return (
                            <option key={option.id} value={option.id}>
                                {option.name}
                            </option>
                        );
                    })}
                </Select>
            </div>
            <div style={{ marginBottom: '20px' }}>
                <Text style={{ marginBottom: '10px' }}>Calculate cost by</Text>
                <Tabs isFitted onChange={(index) => setTabIndex(index)}>
                    <TabList>
                        <Tab>Paste text</Tab>
                        <Tab>Number of words</Tab>
                        <Tab>Number of tokens</Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel>
                            <div>
                                <p>Input Message</p>
                                <Textarea
                                    placeholder="Paste your input message here"
                                    onChange={(e) => setInputMsg(e.target.value)}
                                />
                                <div style={{ textAlign: 'right' }}>
                                    <p style={{ fontSize: '0.8em' }}>{countWords(inputMsg)} words</p>
                                    <p style={{ fontSize: '0.8em' }}>{countTokensFromString(inputMsg)} tokens</p>
                                </div>
                            </div>

                            <div>
                                <p>Output Message</p>
                                <Textarea
                                    placeholder="Paste your output message here"
                                    onChange={(e) => setOutputMsg(e.target.value)}
                                />
                                <div style={{ textAlign: 'right' }}>
                                    <p style={{ fontSize: '0.8em' }}>{countWords(outputMsg)} words</p>
                                    <p style={{ fontSize: '0.8em' }}>{countTokensFromString(outputMsg)} tokens</p>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div>
                                <p>Input Word Count</p>
                                <NumberInput min={0} precision={0} onChange={(valueString) => setInputWordCount(parseInt(valueString))}>
                                    <NumberInputField />
                                </NumberInput>
                                <div style={{ textAlign: 'right' }}>
                                    <p style={{ fontSize: '0.8em' }}>{countTokensFromWordCount(inputWordCount)} tokens</p>
                                </div>
                            </div>
                            <div>
                                <p>Output Word Count</p>
                                <NumberInput min={0} precision={0} onChange={(valueString) => setOutputWordCount(parseInt(valueString))}>
                                    <NumberInputField />
                                </NumberInput>
                                <div style={{ textAlign: 'right' }}>
                                    <p style={{ fontSize: '0.8em' }}>{countTokensFromWordCount(outputWordCount)} tokens</p>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div>
                                <p>Input Token Count</p>
                                <NumberInput min={0} precision={0} onChange={(valueString) => setInputTokenCount(parseInt(valueString, 10))}>
                                    <NumberInputField />
                                </NumberInput>
                            </div>
                            <div>
                                <p>Output Token Count</p>
                                <NumberInput min={0} precision={0} onChange={(valueString) => setOutputTokenCount(parseInt(valueString, 10))}>
                                    <NumberInputField />
                                </NumberInput>
                            </div>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </div>
            <div style={{ margin: 'auto', width: '75%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '50px' }}>
                {(cost > 0) && <p style={{ fontSize: '1.5em', fontWeight: '600' }}>${roundToDecimalPlaces(cost, 5)} per request</p>}
                <Button style={{ marginLeft: 'auto' }} colorScheme='orange' size='lg' onClick={() => getTokens(tabIndex)}>Calculate</Button>
            </div>

            {cost > 0 ? <EstimationTable inputTokens={inputTokens} outputTokens={outputTokens} selectedModel={selectedModel} /> : null}

        </Container>

    )
}

export const EstimationTable = ({ inputTokens, outputTokens, selectedModel }: { inputTokens: number; outputTokens: number; selectedModel: string }) => {
    const [requestsSliderVal, setRequestsSliderVal] = useState(50);
    const niceNumbers = [1, 5, 10, 50, 100, 500, 1000, 5000, 10000, 50000, 100000, 500000, 1000000, 5000000];

    const calculateRequestsPerDay = () => {
        if (requestsSliderVal === 0) {
            return 1; // You can adjust this value if needed
        } else if (requestsSliderVal === 50) {
            return 1000;
        } else if (requestsSliderVal === 100) {
            return 5000000;
        } else {
            // Calculate the exponential value for other cases and round to a nice number.
            const A = 1;
            const k = Math.log(1000 / 1) / 50;
            const exponentialValue = A * Math.exp(k * requestsSliderVal);

            // Find the nearest "nice" number to the exponential value.
            let roundedValue = niceNumbers[0];
            for (const niceNumber of niceNumbers) {
                if (Math.abs(niceNumber - exponentialValue) < Math.abs(roundedValue - exponentialValue)) {
                    roundedValue = niceNumber;
                }
            }

            return roundedValue;
        }
    };
    const [requestsPerDay, setRequestsPerDay] = useState(calculateRequestsPerDay());
    const costModelCostObjectInitialState = models.map((model) => {
        const costPerRequest = calculateCost(model.id, inputTokens, outputTokens);
        const costPerDay = costPerRequest * requestsPerDay;
        const costPerMonth = costPerDay * 30;
        const costPerYear = costPerMonth * 12;
        return { id: model.id, costPerDay, costPerMonth, costPerYear };
    });
    const [modelCostObjectArray, setModelCostObjectArray] = useState(costModelCostObjectInitialState);


    useEffect(() => {
        setRequestsPerDay(calculateRequestsPerDay());
    }, [requestsSliderVal]);

    useEffect(() => {
        const modelCosts = models.map((model) => {
            const costPerRequest = calculateCost(model.id, inputTokens, outputTokens);
            const costPerDay = costPerRequest * requestsPerDay;
            const costPerMonth = costPerDay * 30;
            const costPerYear = costPerMonth * 12;
            return { id: model.id, costPerDay, costPerMonth, costPerYear };
        });
        setModelCostObjectArray(modelCosts);
    }, [requestsPerDay, inputTokens, outputTokens]);

    return (
        <Container maxW='100%'>
            <Card>
                <p>{requestsPerDay} requests per day</p>
            </Card>
            <Slider
                aria-label='slider-ex-4'
                defaultValue={requestsSliderVal}
                min={0} max={100} step={1}
                onChange={(val) => {
                    setRequestsSliderVal(val)
                }}
            >
                <SliderTrack bg='red.100'>
                    <SliderFilledTrack bg='tomato' />
                </SliderTrack>
                <SliderThumb boxSize={6}>
                    <Box color='tomato' />
                </SliderThumb>
            </Slider>

            <TableContainer>
                <Table variant='simple'>
                    <TableCaption>AI Model Cost Comparison</TableCaption>
                    <Thead>
                        <Tr>
                            <Th>Model</Th>
                            <Th style={{ textAlign: 'center' }}>Daily</Th>
                            <Th style={{ textAlign: 'center' }}>Monthly</Th>
                            <Th style={{ textAlign: 'center' }}>Yearly</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {modelCostObjectArray.map(modelCostObject => {
                            const isSelectedModel = selectedModel === modelCostObject.id;
                            const trStyle = {
                                backgroundColor: isSelectedModel ? 'rgba(255, 81, 0, 0.4)' : 'transparent',
                            }
                            const tdStyle = {
                                fontWeight: isSelectedModel ? 'bold' : 'normal',
                                fontSize: isSelectedModel ? '1.1em' : '1em'
                            }
                            return (

                                <Tr style={trStyle} key={modelCostObject.id}>
                                    <Td style={tdStyle}>{modelCostObject.id}</Td>
                                    <Td style={{ ...tdStyle, textAlign: 'center' }}>{Math.ceil(modelCostObject.costPerDay)}</Td>
                                    <Td style={{ ...tdStyle, textAlign: 'center' }}>{Math.ceil(modelCostObject.costPerMonth)}</Td>
                                    <Td style={{ ...tdStyle, textAlign: 'center' }}>{Math.ceil(modelCostObject.costPerYear)}</Td>
                                </Tr>
                            )
                        })}
                    </Tbody>
                </Table>
            </TableContainer>
        </Container>

    )
}