import React from 'react';
import { Badge, Heading } from '@chakra-ui/react';
import { Calculator } from './Calculator';

function App() {
  return (
    <>
      <div
        style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', padding: '25px' }}
      >
        <div
          className='content'
          style={{ backgroundColor: 'white', padding: '1em', borderRadius: '10px', boxShadow: '0 0 10px 2px grey' }}
        >
          <Heading as='h1'>costofai.com</Heading>
          <Badge colorScheme='purple'>BUY THIS DOMAIN FOR ONLY $49,999</Badge>
          <Calculator />
        </div>
      </div>
      <div className='wrapper'><div className="box">
        <div className="single-box" style={{ backgroundImage: "url('https://i.postimg.cc/vHXWB9DN/1.png')" }}></div>
      </div></div>

    </>
  );
}

export default App;
